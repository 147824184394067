import { Header } from "../../components/Header";
import { HeaderTitle } from "../../components/HeaderTitle";
import { HeaderBody } from "../../components/HeaderBody";
import { Navbar } from "../../components/Navbar";
import { Centered } from "../../components/Centered";
import { ArrowButton } from "../../components/ArrowButton";
import { Footer } from "../../components/Footer";
import { LearnMore } from "../../components/LearnMore";
import {validate} from 'email-validator';
import fetch from 'isomorphic-fetch';

import {useState} from 'react';

const ContactForm = () => {
  const states = {
    entry: 0,
    submitting: 1,
    submitted: 2,
  }
  const [state, setState] = useState(states.entry);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [unexpectedError, setUnexpectedError] = useState(false);
  const [validationError, setValidationError] = useState(false);

  if (state === states.submitted) {
    return (<div className='pt-8 pb-8'>
      <p>
        Thank you for contacting us
      </p>
    </div>)
  }
  if (state === states.entry || state === states.submitting) {
    const disabled = state === states.submitting;
    return (
      <div className='pt-8 pb-8'>
        <div>
          <label htmlFor='name' className='text-orange'>Name</label><br/>
          <input id='name' className='text-black w-full' disabled={disabled} value={name} onChange={(e) => setName(e.target.value)}/><br/>
        </div>
        <div className='pt-8'>
          <label htmlFor='email' className='text-orange'>Email</label><br/>
          <input id='email' className='text-black w-full' disabled={disabled} value={email} onChange={(e) => setEmail(e.target.value)}/><br/>
        </div>
        <div className='pt-8'>
          <label htmlFor='message' className='text-orange'>Message:</label><br/>
          <textarea id='message' className='text-black w-full h-full h-52' disabled={disabled} value={message} onChange={(e) => setMessage(e.target.value)}/><br/>
        </div>
        <div className='pt-8'>
          <ArrowButton
            className='bg-orange hover:bg-orange-dark text-sm w-1/3'
            onClick={async () => {
                if (disabled) {
                  return;
                }
                
                if (!(name && email && message && validate(email))) {
                  setValidationError(true);
                  return;
                } else {
                  setValidationError(false);
                }

                setState(states.submitting);
                const result = await fetch(process.env.REACT_APP_CONTACT_API, {
                  method: 'POST',
                  body: JSON.stringify({
                    "name": name,
                    "email": email,
                    "message": message
                  })
                });
                if (result.ok) {
                  setState(states.submitted);
                  setUnexpectedError(false);
                } else {
                  setState(states.entry);
                  setUnexpectedError(true);
                }
              }
            }
          >
          Submit
          </ArrowButton>
          {unexpectedError && <>An error occured while submitting your contact request, please check your internet connection and try again</>}
          {validationError && <>Please make sure to enter a valid email and complete all fields</>}
        </div>
      </div>
    )
  }
}

const ContactUs = () => {
  return (
    <div className="mx-auto">
      <div className='text-xl sm:text-2xl'>
        <div className="bg-black text-white">
          <Centered>
            <div className="flex flex-row md:space-x-4">
              <div className="md:w-2/3">
                <ContactForm />
              </div>
            </div>
          </Centered>
        </div>
      </div>
      <div className="bg-frost">
        <Footer />
      </div>
    </div>
  );
};

export { ContactUs };
