import "./index.css";
import { Header } from "../../components/Header";
import { HeaderTitle } from "../../components/HeaderTitle";
import { HeaderBody } from "../../components/HeaderBody";
import { Navbar } from "../../components/Navbar";
import { ArrowButton } from "../../components/ArrowButton";
import { Footer } from "../../components/Footer";
import { Centered } from "../../components/Centered";
import { Link } from "react-router-dom";

const Card = ({ children, title, button }) => (
  <div className="flex flex-col p-8 w-full md:w-1/3 bg-white">
    <p className="text-xl mb-4 sm:text-3xl min-h-[75px]">{title}</p>
    <div className="flex flex-col space-y-3 grow">{children}</div>
    {button}
  </div>
);

const Middle = () => (
  <div className="bg-swirl-4 p-4">
    <Centered>
      <div className="mb-8 md:mb-16 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2">
        <div className="w-full md:w-2/3 bg-white p-4">
          <p className="text-xl sm:text-3xl">
            BYLD is a team of property data experts.
          </p>
          <p className="text-xl sm:text-3xl">
            We help clients reduce data noise, verify data, and improve data
            collection.
          </p>
        </div>
        <div className="hidden md:block md:w-1/3"></div>
      </div>
      <div className="sm:pb-32 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <Card
          title={<span className='text-orange'>We reduce risk</span>}>
          <p>
            Through efficient data collection and usage, you'll stay on top of regulator or internal reporting deadlines.
          </p>
          <p>
            We can help you access reliable, clean, and audit-ready data. making it easier for you to adhere to standards, reply to regulator requests, and report on fund or building performance.
          </p>
        </Card>
        <Card
          title={<span className='text-purple'>We create operational efficiencies</span>}
          >
          <p>
            We help clients reduce costs by automating data collection and removing data noise.
          </p>
          <p>
            By being able to query all your data in one place, you don't need to rush around departments sharing thumbdrives or forwarding documents. No more spreadsheet-hell.
          </p>
        </Card>
        <Card
          title={<span className='text-swamp'>We modernise systems</span>}>
          <p>
            We make sure your existing systems work, run faster, are more reliable and cheaper to run.
          </p>
          <p>
            We can open up locked-away datasets, and do all the necessary cleaning, transforming and re-hosting required.
          </p>
        </Card>
      </div>
    </Centered>
  </div>
);

const WhyByld = () => {
  return (
    <div className="p-4">
      <div className="container mx-auto sm:w-3/4 xl:w-1/3 pt-8">
        <p className="text-xl sm:text-2xl">Why BYLD?</p>
        <p className="text-xl sm:text-2xl text-byldGray">
          We talk the same language, don't over-engineer, and are fast and
          flexible
        </p>
        <div className='pt-8 pb-16'>
          <Link to="/about-us">
            <ArrowButton width="w-1/3">
              About us
            </ArrowButton>
          </Link>
        </div>
      </div>
      <div className="hidden md:block dot-grid-grey p-8">
        <Centered>
          <div className="columns-3">
            <div className="min-h-[400px] flex flex-col justify-center">
              <img src={new URL(
                "../../assets/images/derived/austin-distel.jpg",
                import.meta.url
              )} />
            </div>
            <div className="min-h-[400px] flex flex-col items-center justify-end">
              <img src={new URL(
                "../../assets/images/derived/workers-2.jpg",
                import.meta.url
              )} />
            </div>
            <div className="min-h-[400px] flex flex-col justify-start">
              <img src={new URL(
                "../../assets/images/derived/sigmund.jpg",
                import.meta.url
              )} />
            </div>
          </div>
        </Centered>
      </div>
    </div>
  );
};

//disabled until graphics are made
const Diagram = () => (<div className="bg-dark-blue text-white">
  <Centered>
    <p className="text-xl"><span className='font-bold'>Before</span><br/>
      <img alt='diagram here'/>
      Low confidence in data<br/>
      High cost to get data due to:
      <ul className='list-dash list-inside'>
        <li>Different formats</li>
        <li>Requires validation</li>
      </ul>
    </p>
    <p className="text-xl"><span className='font-bold'>After</span><br/>
      <img alt='diagram here'/>
      <ul className='list-dash list-inside'>
        <li>Fast</li>
        <li>Reliable</li>
        <li>Cost effective</li>
      </ul>
    </p>
  </Centered>
</div>);

const Home = () => (
  <div className="mx-auto">
    <Middle />
    {false && <Diagram/>}
    <div className="bg-frost">
      <WhyByld />
      <Footer />
    </div>
  </div>
);

export { Home };
