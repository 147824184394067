import {
  createHashRouter,
  RouterProvider,
  useLocation,
  useOutlet,
} from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Home } from "./pages/Home";
import { Products } from "./pages/Products";
import { AboutUs } from "./pages/AboutUs";
import { Services } from "./pages/Services";
import { CaseStudies } from "./pages/CaseStudies";
import { Navbar } from "./components/Navbar";
import { Header } from "./components/Header";
import { HeaderBody } from "./components/HeaderBody";
import { HeaderTitle } from "./components/HeaderTitle";
import { ContactUs } from "./pages/ContactUs";
import { createRef } from "react";

const HomeHeader = () => (
  <div className="bg-dark text-white">
    <Navbar logoColour="white" />
    <Header></Header>
  </div>
);

const ServicesHeader = () => (
  <div className="bg-frost text-dark-blue">
    <Navbar logoColour="black" />
    <Header></Header>
  </div>
);

const ProductsHeader = () => (
  <div className="bg-dark-blue text-white">
    <Navbar logoColour="white" />
    <Header></Header>
  </div>
);

const routes = [
  {
    path: "/",
    name: "Home",
    element: <Home />,
    nodeRef: createRef(),
    header: <HomeHeader />,
    colour: "bg-dark",
    textColour: "text-white",
    logoColour: "white",
    swirls: ['swirls-0'],
    navStyle: 'home',
    header: {
      title: (
        <HeaderTitle>
          <p>
            Solving enterprise data problems
          </p>
          <p>
            Big and small.
          </p>
        </HeaderTitle>
      ),
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-3xl mb-8">
            We bring <span className='text-orange'>trust and confidence to a client's data.</span>
            <br />
            Helping them build systems that process, use, and get value from
            data.
          </p>
        </HeaderBody>
      ),
    },
  },
  {
    path: "/products",
    name: "Products",
    element: <Products />,
    nodeRef: createRef(),
    header: <ProductsHeader />,
    colour: "bg-dark-blue",
    textColour: "text-white",
    logoColour: "white",
    swirls: ["swirls-1"],
    navStyle: 'products',
    header: {
      title: (
        <HeaderTitle>
          <p>
            Demand driven products that solve industry wide problems
          </p>
        </HeaderTitle>
      ),
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-3xl mb-8">
            There's no point solving the same problem twice, so we bootstrap our own products to get our clients off the ground quickly.
          </p>
        </HeaderBody>
      ),
    },
  },
  {
    path: "/services",
    name: "Services",
    element: <Services />,
    nodeRef: createRef(),
    header: <ServicesHeader />,
    colour: "bg-frost",
    textColour: "text-black",
    logoColour: "black",
    swirls: ["swirls-2"],
    navStyle: 'services',
    header: {
      title: (
        <HeaderTitle>
          <p>
            Bringing trust & confidence to enterprise data
          </p>
        </HeaderTitle>
      ),
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-3xl mb-8">
            Understand where data came from, why it's valuable, and what you can
            do with it.
          </p>
        </HeaderBody>
      ),
    },
  },
  {
    path: "/case-studies",
    name: "Clients",
    element: <CaseStudies />,
    nodeRef: createRef(),
    colour: "bg-purple",
    textColour: "text-white",
    logoColour: "white",
    swirls: ["swirls-3"],
    navStyle: 'caseStudies',
    header: {
      title: (
        <HeaderTitle>
          <p>
            Working with the next generation of data driven professionals
          </p>
        </HeaderTitle>
      ),
      
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-2xl">
            We help our clients build data centric products that solve real problems and deliver meaningful outcomes.
          </p>
        </HeaderBody>
      ),
    },
  },
  {
    path: "/about-us",
    name: "About",
    element: <AboutUs />,
    nodeRef: createRef(),
    colour: "bg-orange",
    textColour: "text-white",
    logoColour: "white",
    navStyle: 'aboutUs',
    swirls: ["swirls-4"],
    header: {
      title: (
        <HeaderTitle>
          <p>
            Hello, we're BYLD
          </p>
        </HeaderTitle>
      ),
      
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-3xl mb-8">
          </p>
        </HeaderBody>
      ),
    },
  },
  {
    path: '/contact-us',
    name: 'Contact',
    element: <ContactUs />,
    nodeRef: createRef(),
    colour: "bg-byldNavyPurple",
    textColour: "text-white",
    logoColour: "white",
    navStyle: 'contactUs',
    swirls: ["swirls-4"],
    header: {
      title: (
        <HeaderTitle>
          <p>
            Contact us
          </p>
        </HeaderTitle>
      ),
      
      body: (
        <HeaderBody>
          <p className="text-xl sm:text-3xl mb-8">
          </p>
        </HeaderBody>
      ),
    },
  }
];

const Page = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const currentRoute =
    routes.find((route) => route.path === location.pathname) ?? {};
  const { nodeRef } = currentRoute;
  return (
    <>
      <div
        className={`${currentRoute.colour} ${currentRoute.textColour}`}
        style={{
          transition: "all 1000ms ease-in",
        }}
      >
        <Navbar
          logoColour={currentRoute.logoColour}
          navStyle={currentRoute.navStyle || 'default'}
        />
        <Header>
          <div className="dot-grid-grey">
            <div className={`headerBanner swirls ${currentRoute.swirls.join(' ')}`}>
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  timeout={1000}
                  classNames="header-transition"
                  unmountOnExit
                >
                  {(state) => (
                    <div className="header-transition">
                      {currentRoute.header.title}
                    </div>
                  )}
                </CSSTransition>
              </SwitchTransition>
            </div>
          </div>
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              timeout={1000}
              classNames="header-transition"
              unmountOnExit
            >
              {(state) => (
                <div className="header-transition">
                  {currentRoute.header.body}
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </Header>
      </div>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={1000}
          classNames="page"
          unmountOnExit
        >
          {(state) => (
            <div ref={nodeRef} className="page">
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export function App() {
  const router = createHashRouter([
    {
      path: "/",
      element: <Page />,
      children: routes.map((route) => ({
        index: route.path === "/",
        path: route.path === "/" ? undefined : route.path,
        element: route.element,
      })),
    },
  ]);

  return <RouterProvider router={router} />;
}
