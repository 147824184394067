import { Link } from "react-router-dom";

//These are currently named after the only pages they're used on, since there are so few
const linkStyle = (style) => {
  if (style === 'home') {
    return "text-byldLightGray hover:text-white hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
  if (style === 'products') {
    return "text-byldLightGray hover:text-white hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
  if (style === 'services') {
    return "text-dark hover:text-dark hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
  if (style === 'caseStudies') {
    return "text-dark hover:text-white hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
  if (style === 'aboutUs') {
    return "text-dark hover:text-white hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
  
  if (style === 'contactUs') {
    return "text-byldLightGray hover:text-white hover:underline hover:decoration-dotted decoration-orange decoration-4 underline-offset-8";
  }
}

const Navbar = ({ logoColour, navStyle }) => {
  const logos = {
    black: new URL("../../assets/images/originals/logo-black.png", import.meta.url),
    white: new URL("../../assets/images/originals/logo-white.png", import.meta.url),
  };
  const linkDecoration = linkStyle(navStyle);
  
  return (
    <div className="container mx-auto p-4 w-full xl:w-2/3 2xl:w-1/2 sm:flex sm:justify-between">
      <div className="w-1/5">
        <Link to="/">
          <img width={128} src={logos[logoColour]} className="" />
        </Link>
      </div>
      <ul className="self-end flex sm:mb-8 lg:text-xl">
        <Link to='/' className={`${linkDecoration} hidden sm:block`}>
          <li>Home</li>
        </Link>
        <Link to="/services" className={`${linkDecoration} sm:ml-4`}>
          <li>Services</li>
        </Link>
        <Link to="/products" className={`${linkDecoration} ml-4`}>
          <li>Products</li>
        </Link>
        <Link to="/case-studies" className={`${linkDecoration} ml-4`}>
          <li>Clients</li>
        </Link>
        <Link to="/about-us" className={`${linkDecoration} ml-4`}>
          <li>About</li>
        </Link>
        <Link to="/contact-us" className={`${linkDecoration} ml-4`}>
          <li>Contact</li>
        </Link>
      </ul>
    </div>
  );
};

export { Navbar };
