import { Centered } from "../Centered";

const HeaderBody = ({ children }) => (
  <Centered>
    <div className="mt-4 sm:pb-16 sm:pt-16 lg:w-4/5">
      {children}
    </div>
  </Centered>
);

export { HeaderBody };
