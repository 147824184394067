import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import { HeaderBody } from "../../components/HeaderBody";
import { HeaderTitle } from "../../components/HeaderTitle";
import { Centered } from "../../components/Centered";

const SuperNumber = ({number, className}) => <sup className={`${className} text-white text-sm pr-2 pl-0.5 pb-1 align-text-top`}>{number}</sup>

const OurWork = () => (
    <Centered>
      <div className='mb-32 mt-16'>
        <div className="container flex flex-col flex-wrap md:flex-row">
          <div className="bg-white md:w-1/2 p-1 mb-8">
            <p className="p-2 font-semibold">Our work</p>
          </div>
          <div className="md:w-1/2"></div>
        </div>
        <ol className="container mx-auto flex flex-col flex-wrap md:flex-row">
          <li className="w-full md:w-1/2 p-3 bg-white">
            <p className="text-orange font-semibold">Bespoke reporting dashboards <SuperNumber number={1} className='bg-orange'/></p>
            <p>Create a bespoke reporting dashboard that integrates business intelligence.</p>
          </li>
          <li className="w-full md:w-1/2 p-3 bg-white">
            <p className="text-swamp font-semibold">Find operational efficiencies <SuperNumber number={2} className='bg-swamp'/></p>
            <p>Clean up noisy Building Management Systems and find operation efficiencies.</p>
          </li>
          <li className="w-full md:w-1/2 p-3 bg-white">
            <p className="text-light-blue font-semibold">Automation <SuperNumber number={3} className='bg-light-blue'/></p>
            <p>Automate a number of manual data-processing tasks, e.g: input, validation and paper processing, or codify logic from spreadsheets to turn fragmented internal systems into automated pipelines and applications.</p>
          </li>
          <li className="w-full md:w-1/2 p-3 bg-white">
            <p className="text-purple font-semibold">Applications & Infrastructure <SuperNumber number={4} className='bg-purple'/></p>
            <p>Build data-intensive applications and infrastructure</p>
          </li>
          <li className="w-full md:w-1/2 p-3 bg-white">
            <p className="text-navy font-semibold">Sustainability data collection <SuperNumber number={5} className='bg-navy'/></p>
            <p>Collect and transform sustainability data so you know teh real carbon numbers to offset.</p>
          </li>
        </ol>
      </div>
    </Centered>
);

const HowByldCanHelp = () => {
  return (
    <Centered>
      <div className="flex flex-col space-y-4 mt-8">
        <div className='w-1/2 p-4'>
          <p className='font-bold'>How BYLD Can Help</p>
          <p className='font-light'>
            We work with clients in a range of ways.
            Some want all three services below, some just want one. We're fully
            flexible.
          </p>
        </div>
        <div className="flex">
          <div className="w-1/2 mr-2">
            <img src={new URL(
              "../../assets/images/derived/1:1/marilia-castelli.jpg",
              import.meta.url
            )} />
          </div>
          <div className="w-1/2 ml-2 p-4 bg-white">
            <p className='font-semibold text-orange mb-1'>Plan</p>
            <p className='font-light'>Have a problem but don't know where to begin? We'll help you shape the solution and outline a scope work that includes a phased approach to tackling the problem.</p>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 p-4 bg-white mr-2">
            <p className='font-semibold text-purple mb-1'>Solution</p>
            <p className='font-light'>Know the solution but need help getting started? We can run a proof of concept or, if you're ready to go, take it off your hands completely.</p>
          </div>
          <div className="w-1/2 ml-2">
            <img src={new URL(
              "../../assets/images/derived/1:1/linkedin-sales-solutions.jpg",
              import.meta.url
            )} />
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 mr-2">
            <img src={new URL(
              "../../assets/images/derived/1:1/annie-spratt.jpg",
              import.meta.url
            )} />
          </div>
          <div className="w-1/2 p-4 bg-white ml-2">
            <p className='font-semibold text-swamp mb-1'>In-flight project</p>
            <p className='font-light'>Ideal if your team is already working on a project and you need data engineering or development support. We become part of your team to fill any capability gaps.</p>
          </div>
        </div>
      </div>
    </Centered>
  );
};

const Services = () => {
  return (
    <div className='mx-auto'>
      <div className="text-xl sm:text-2xl">
        <div className="bg-swirl text-dark-blue">
          <OurWork />
        </div>
        <div className='bg-light-blue'>
          <HowByldCanHelp />
        </div>
      </div>
      <div className='bg-light-blue'>
        <Footer />
      </div>
    </div>
  );
};

export { Services };
