import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="p-4 font-light">
      <div className="container mx-auto flex flex-col space-y-1 sm:space-y-0 sm:flex-row w-full xl:w-2/3 2xl:w-1/2">
        <div className="w-1/3">
          <p>Contact</p>
          <p><a href='mailto:hello@byld.uk'>hello@byld.uk</a></p>
        </div>
        <div className="w-1/3">
        </div>
        <div className="w-1/3">
          <p>&#169;BYLD UK 2022</p>
        </div>
      </div>
    </div>
  );
};

export { Footer };
