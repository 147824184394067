import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import { HeaderTitle } from "../../components/HeaderTitle";
import { HeaderBody } from "../../components/HeaderBody";
import { Navbar } from "../../components/Navbar";
import { Centered } from "../../components/Centered";
import { ArrowButton } from "../../components/ArrowButton";
import { Footer } from "../../components/Footer";
import { LearnMore } from "../../components/LearnMore";

const Products = () => {
  return (
    <div className="mx-auto">
      <div className='text-xl sm:text-2xl'>
        <div className="bg-black text-white">
          <Centered>
            <div className="mb-4">
              <p><img width={256} className='pt-8 pb-8' src={new URL(
                "../../assets/images/originals/fetch-esg-logo-white.png",
                import.meta.url
              )}/></p>
            </div>
            <div className="flex flex-row md:space-x-4">
              <div className="md:w-2/3">
                <p className="font-semibold text-purple pb-1">
                  Automated ESG data
                </p>
                <p className="mb-4">
                  FetchESG handles the messy work of scope 3 carbon data collection by automating everything from start to finish.
                </p>
                <p>
                  It works on every building and delivers high quality data without anyone having to lift a finger, saving time and money whilst also ensuring sustainability strategy and reports are based off the right numbers.
                </p>
                <div className='pt-8 pb-8'>
                  <a href="https://fetchesg.com"><ArrowButton className='bg-ogre hover:bg-ogre-dark text-sm w-1/3'>Book a demo</ArrowButton></a>
                </div>
              </div>
              <div className="hidden md:block md:w-1/3 imagedotcontainer">
                <img className='p-5' src={new URL(
                  "../../assets/images/derived/denys-nevozhai.jpg",
                  import.meta.url
                )} />
              </div>
            </div>
          </Centered>
        </div>
        <div className="bg-mint">
          <Centered>
            <div className="mb-4">
              <p><img className='pt-8 pb-8' width={256} src={new URL(
                "../../assets/images/originals/byld-tech.png",
                import.meta.url
              )}/></p>
            </div>
            <div className="flex flex-row md:space-x-4">
              <div className="md:w-2/3">
                <p className="font-semibold pb-1">
                  A city scale data layer for commercial real estate
                </p>
                <p className="mb-4">
                  BYLD.tech accelerates PropTech SaaS development. It connects, cleans and delivers property data for development, BI and data science teams and allows you to query data at city-scale.
                </p>
                <div className='pt-8 pb-8'>
                  <Link to='/contact-us'><ArrowButton className='bg-navy hover:bg-black text-sm text-sm w-1/3'>Book a demo</ArrowButton></Link>
                </div>
              </div>
              <div className="hidden md:block md:w-1/3 imagedotcontainer">
                <img className='p-5' src={new URL(
                  "../../assets/images/derived/altumcode.jpg",
                  import.meta.url
                )} />
              </div>
            </div>
          </Centered>
        </div>
      </div>
      <div className="bg-frost">
        <Centered>
          <LearnMore/>
        </Centered>
        <Footer />
      </div>
    </div>
  );
};

export { Products };
