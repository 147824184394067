import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import { HeaderBody } from "../../components/HeaderBody";
import { HeaderTitle } from "../../components/HeaderTitle";
import { Centered } from "../../components/Centered";

const AboutContent = () => (
  <Centered>
      <div className="container flex flex-col flex-wrap md:flex-row text-xl sm:text-2xl">
        <div className="bg-white p-1 mb-8 mx-auto w-5/6">
          <p className='bg-white p-1 mb-8'>
          Enterprise organisations rely heavily on outdated systems, restricted SaaS platforms and oceans of spreadsheets. Not only is the data difficult to acquire but also difficult to rely on.
          </p>
          <p className='bg-white p-1 mb-8'>
          BYLD was founded in 2021 to fix this.
          </p>
          <p className='bg-white p-1 mb-8'>
          We work with clients to ensure data is clean, dependable, and useful.
          </p>
          <p className='bg-white p-1 mb-8'>
          We are a team of data experts with a proven history of solving intricate data-related issues within complex sectors ranging from government to financial services.
          </p>
          <p className='bg-white p-1 mb-8'>
          If data is slowing you down, get in touch. We talk the same language, don't over-complicate things, and are fast and flexible.
          </p>
        </div>
      </div>
    </Centered>
);

const AboutUs = () => {
  return (
    <div className="mx-auto">
      <div className="bg-building text-dark-blue">
        <AboutContent />
      </div>
      <div className='bg-light-blue'>
        <Footer />
      </div>
    </div>
  );
};

export { AboutUs };
