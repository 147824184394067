import "./index.css";

const SpeechBubble = ({ backgroundColour, textColour, children }) => {
  return (
    <div className={`bg-${backgroundColour} text-${backgroundColour} bubble bubble-bottom-left mb-4`}>
      <div className={`text-${textColour}`}>
        {children}
      </div>
    </div>
  );
};

export { SpeechBubble };
