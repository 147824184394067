const ArrowButton = ({ children, width, className = 'bg-orange hover:bg-orange-dark', onClick }) => (
  <div
    className={`${className} text-base cursor-pointer rounded-full text-white flex content-around py-1 px-3 my-5 ${width}`}
    onClick={onClick}
  >
    <p className="grow self-start">{children}</p>
    <p className="self-end">⟶</p>
  </div>
);

export { ArrowButton };
