import { Centered } from "../../components/Centered";
import { Footer } from "../../components/Footer";
import { SpeechBubble } from "../../components/SpeechBubble";
import { LearnMore } from "../../components/LearnMore";

const KinriseTestimonial = () => null
//disabled until there is a testimonial to use
/*
const KinriseTestimonial = () => <SpeechBubble backgroundColour='byldGold' textColour="black">
    "Testimonial here"<br/>
    Samuel Lawson Johnston<br/>
    Managing Partner, Kinrise
  </SpeechBubble>
*/
const CaseStudies = () => {
  return (
    <div className="mx-auto">
      <div className="bg-byldNavyPurple text-white text-xl sm:text-2xl">
        <Centered>
          <div className="mb-8">
            <p className="pt-16 pb-8"><img width={222} src={new URL(
              '../../assets/images/originals/kinrise.png',
              import.meta.url
            )}/></p>
          </div>
          <div className="mb-1">
            <p className="text-sm"><a href='https://kinrise.com'>kinrise.com</a></p>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2 pr-16">
              <div>
                <p className='mb-8'>
                  Kinrise is an award winning investment company focused on reimagining and restoring iconic buildings to their former glory.
                </p>
              </div>
              <div><img className='p-5' src={new URL(
                "../../assets/images/derived/raj-rana.jpg",
                import.meta.url
              )}/></div>
              <div>
                <p className='mb-8'>
                  <span className='font-semibold mb-1'>Problem</span><br/>
                  Commercial carbon usage reporting is expensive, slow to gather and error-prone. This means organisations frequently act on bad data and waste money.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
            <div><img className='p-5' src={new URL(
              "../../assets/images/originals/philipp-mandler.jpg",
              import.meta.url
            )}/></div>
              <div>
              <p className='mb-8'>
                  <span className='font-semibold mb-1'>Solution</span><br/>
                  BYLD partnered with Kinrise to develop a fully automated sustainability pipeline that captures and transforms raw building in-use data (inc. energy, tenant activities, carbon and embodied carbon) into open, clean datasets for reporting teams to use. The system is being trialed inside industry.
                </p>
              </div>
              <KinriseTestimonial/>
            </div>
          </div>
        </Centered>
      </div>
      <div className="bg-swamp text-white text-xl sm:text-2xl">
        <Centered>
          <div className="mb-8">
            <p className="pt-16 pb-8"><img src={new URL(
              '../../assets/images/originals/resustain_logo_white.png',
              import.meta.url
            )}/></p>
          </div>
          <div className="mb-1">
            <p className="text-sm"><a href='https://resustain.com'>resustain.com</a></p>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2 pr-16">
              <div>
                <p className='mb-8'>
                REsustain reduces energy consumption in commercial real estate, leading to lower operating costs and a measurable reduction in carbon output.
                </p>
              </div>
              <div><img className='p-5' src={new URL(
                "../../assets/images/derived/alexander-abero.jpg",
                import.meta.url
              )}/></div>
              <div>
                <p className='mb-8'>
                  <span className='font-semibold mb-1'>Problem</span><br/>
                  Inefficient HVAC systems account for 40% of all building energy waste. This a tricky problem to solve as HVAC systems are all unique and produce large amounts of dirty data that is difficult to normalise for analysis at scale.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
            <div><img className='p-5' src={new URL(
              "../../assets/images/derived/charisse-kenion.jpg",
              import.meta.url
            )}/></div>
              <div>
              <p className='mb-8'>
                  <span className='font-semibold mb-1'>Solution</span><br/>
                  BYLD developed a go-to-market MVP within 3 months using REsustain's algorithms. The product delivered massive cost savings within portfolios and helped secure investment. BYLD continued to work with REsustain for 12 months to scale and improve analysis and cost savings solutions using REsustain technology and the BYLD.tech big data platform. 
                </p>
              </div>
              <KinriseTestimonial/>
            </div>
          </div>
        </Centered>
      </div>
      <div className="bg-frost">
        <Centered>
          <LearnMore/>
        </Centered>
        <Footer />
      </div>
    </div>
  );
};

export { CaseStudies };
